.functionality_item{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
}

.functionality_item_selected{
    width: 100%;
    border-radius: 4px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    margin: 10px 0px;
    /* outline: 1px solid rgb(180, 211, 241); */
    outline:1px solid rgb(244, 244, 244);
   transition: all 0.3s ease;
    background-color: rgb(244, 244, 244);
}
.functionality_item:hover{
    transition: all 0.3s ease;
    background-color: rgb(237, 237, 237);

}
.functionality_drawer_button{
    position: fixed;
    top: 20px;
    right:0;
}