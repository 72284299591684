body {
  font-family: 'MyCustomFont', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem; /* Scales better for accessibility */
  background-color: #fafafa;
  width: 100%;
  height: 100%;
}

body {
  padding: 20px;
}

html {
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left !important;
}

.move-end {
  float: right;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  transform: scale(.7);
  left: 0;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.5em);
}

::selection {
  color: #fff;
  background: #1890ff;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

@media (min-width: 768px) {
  .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

.main-sec {
  width: 100%;
}

/* .fix-width {
  max-width: 450px;
  
} */
img.view-pass {
  float: right;
  margin-right: 10px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.logo-area {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-area img {
  width: 35%;
  /* margin-top: 5%; */
  padding: 5%;
}

.login-text {
  margin-top: 5%;
  margin-bottom: 5%;
}

.login-title h2 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}

.login-subtitle {
  font-size: 15px;
  font-weight: 500;
}

.login-form-holder {
  align-items: center;
}

.login-form-holder ul {
  padding: 10px;
  background: #eff5f6;
  border: none;
  border-radius: 50px;
}

.login-form-holder ul li.nav-item {
  width: 48%;
  text-align: center;
}

/*.login-form-holder ul li.nav-item:active {
  width: 48%;
  text-align: center;
  border-radius: 50px;
  margin: 1%;
  background: #FFFFFF;
}*/
.login-form-holder ul li.nav-item a {
  color: #818080;
  padding: 3%;
  font-size: 16px;
  /*20px;*/
  font-weight: 500;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  border-radius: 50px;
  margin: 1%;
  background-color: #ffffff;
  color: #010101;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.nav-pills a.active {
  border-radius: 50px;
  margin: 1%;
  background-color: #ffffff;
  color: #010101;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.email-login {
  margin-top: 10%;
  margin-bottom: 15%;
}

.email-label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5%;
}

.email-box {
  border-radius: 50px;
  padding: 1rem 0.75rem;
}

.password-box {
  border-radius: 50px;
  padding: 1rem 0.75rem;
  width: 100%;
  float: left;
}

.email-submit {
  width: 100%;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 2%;
  padding: 2%;
}

.ortext {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding: 3%;
}

.otp-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 2%;
  margin-bottom: 15%;
  padding: 2%;
}

/*------------------- Toggle Switch ----------------------*/
.toggle_switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggler-div {
  margin: 5px 20px 20px;
  text-align: center;
  align-items: center;
}

.checkbox-label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 30px;
  background: #eff5f6;
  display: block;
  border-radius: 100px;
  position: relative;
  /* border: 1px solid #ced4da; */
}

.checkbox-label:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 0px;
  width: 30px;
  height: 30px;
  background: #1fa1c1;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked+.checkbox-label:after {
  left: calc(100% - 0px);
  transform: translateX(-100%);
}

/*  */

.dashboard-card-holder {
  align-items: center;
}

.card-border-small {
  border-radius: 20px;
  box-shadow: 0px 5px 6px #e1e1e1;
  width: 45%;
  float: left;
  margin: 2% 2%;
  height: 160px;
  padding: 5%;
  cursor: pointer;
}

.card img {
  width: 70px;
}

.image-holder {
  text-align: center;
}

.image-holder img {
  background-color: #41d9d0;
}

.profile-image-holder img {
  background-color: #49BB4332;
  padding: 5%;
  border-radius: 10px;
}

.float-left {
  float: left !important;
}

span.profile-name {
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}

span.profile-contact {
  font-size: 15px;
  font-weight: 400;
}

.seclabel {
  font-size: 17px;
  font-weight: 400;
  padding-top: 3%;
}

.profile-notif-holder img {
  background-color: #49BB4332;
  border-radius: 10px;
  padding: 10%;
}

input[type="checkbox"].switch_1 {
  font-size: 20px;
  appearance: none;
  width: 3.5em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all .2s ease-in-out;
  display: flex
;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  transform: scale(.7);
  left: 0;
  transition: all .2s ease-in-out;
}

.profile-adrenalin-holder img {
  background-color: #A3C8E1;
  border-radius: 10px;
  padding: 10%;
}

.profile-logout-holder img {
  background-color: #D9724A32;
  border-radius: 10px;
  padding: 10%;
}

span.profile-logout-name {
  font-size: 18px;
  color: #F20F0F;
  font-weight: 400;
}

.card img {
  width: 60px;
}

.email-error {
  color: red !important;
  font-size: 14px;
  margin-top: 2%;
  margin-left: 5%;
}

/* Dashboard Start */
.dashboard-card-holder {
  align-items: center;
  /* margin-top: 10%; */
}

.card-border {
  border-radius: 20px;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.image-holder {
  text-align: center;
}

.image-holder img {
  background-color: #41d9d0;
  border-radius: 100px;
  padding: 10px;
}

.card-heading,
.card-subheading {
  margin-top: 5%;
  text-align: center;
}

.card-body {
  padding: 2%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.card-heading h2 {
  font-size: 18px;
  font-weight: 600;
  min-width: max-content;
}

.card-subheading h2 {
  font-size: 17px;
  font-weight: 400;
}

.image-holder-2 {
  text-align: center;
}

.image-holder-2 img {
  background-color: #0178b7;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-3 {
  text-align: center;
}

.image-holder-3 img {
  background-color: #d49378;
  border-radius: 100px;
  padding: 10px;
}

.image-holder-4 {
  text-align: center;
}

.image-holder-4 img {
  background-color: #5fb270;
  border-radius: 100px;
  padding: 10px;
}

/* Dashboard Start */
/* OTP Verify Start */
.otp {
  margin-bottom: 63px;
}

.otp-form {
  text-align: center;
}

.title {
  margin: 0 auto;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}

.title_text {
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 39px;
}

.mob_number {
  margin: 0 auto 63px;
  font-size: 26;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
}

.icon_pen {
  text-align: center;
  background-color: #1fa1c1;
  border-radius: 50%;
  padding-top: 1%;
  height: 35px;
  width: 35px;
}

.opt-container {
  margin-left: 41px;
  margin-right: 40px;
}

.otp {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #090909;
  font-size: 25px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #eff5f6;
  outline: none;
  border: 0;
  margin: 0 8px 67px 8px;
}

.btn_css {
  width: 85%;
  background: #1fa1c1;
  margin: 0 25px 46px 26px;
  height: 55px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 30px;
  padding: 0 10px;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(76, 167, 183, 1);
  -moz-box-shadow: 0px 3px 6px 0px rgba(76, 167, 183, 1);
  box-shadow: 0px 3px 6px 0px rgba(76, 167, 183, 1);
}

.images {
  margin-top: 26px;
  margin-bottom: 53px;
}

.user {
  height: 229px;
  width: 203px;
  margin: 0 auto;
}

/* OTP Verify End */
/* Reportd */
.header-area {
  margin: 5% 0 5% 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.menu-image-holder {
  background: #eff5f6;
  padding: 3%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
}

img.menu-image {
  width: 25px;
}

.head-title {
  font-size: 20px;
  font-weight: 700;
  float: left;
  padding: 10px;
  /* margin-right: 5%; */
}

.menu-image-holder2 {
  background: #eff5f6;
  padding: 2%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
  margin: 1%;
}

img.menu-image2 {
  width: 33px;
}

.menu-image-holder3 {
  background: #eff5f6;
  padding: 2%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  float: left;
  justify-content: center;
  margin: 1%;
}

img.menu-image3 {
  width: 26px;
}

.main-area {
  background: #eff5f6;
  border-radius: 1px 30px 30px 1px;
  box-shadow: 0px 5px 6px #e1e1e1;
}

.title-holder h2 {
  font-size: 19px;
  color: #1fa1c1;
  /* margin: 2%;
  background-color: #EFF5F6;
  padding: 3%;
  border-radius: 15px; */
}

.title-holder_bg {
  display: flex;
  align-items: center;
  margin-top: 2%;
  border-radius: 15px;
  margin-bottom: 2%;
}

.title-holder_bg h2 {
  font-size: 19px;
  color: black;
  margin: 2%;
  display: inline;
}

.title-holder {
  /* margin: 5% 0 5% 0; */
}

.name-holder {
  padding: 5% 0;
}

.name-holder h2 {
  font-size: 17px;
  font-weight: 600;
  padding: 5%;
  background: #1fa1c1;
  border-radius: 1px 50px 50px 1px;
  color: #ffffff;
  float: left;
  text-transform: capitalize;
}

.demand-holder {
  text-align: inherit;
  padding-left: 60%;
}

.demand-holder h2 {
  font-size: 16px;
  font-weight: 600;
  color: #1fa1c1;
}

.demand-holder span {
  font-size: 16px;
  font-weight: 500;
}

.main-foor-part {
  padding: 1%;
}

.collected-holder h2 {
  font-size: 16px;
  width: 50%;
  font-weight: 500;
  color: #49bb43;
}

.collected-holder {
  margin-left: 7%;
  width: 40%;
  position: relative;
  display: inline-block;
}

.collected-holder span {
  font-size: 16px;
  font-weight: 500;
}

.balance-holder {
  width: 50%;
  display: inline-block;
  position: relative;
  padding-left: 13%;
}

.balance-holder h2 {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
  text-align: inherits;
  color: #d9724a;
}

.balance-holder span {
  font-size: 16px;
  font-weight: 500;
}

.report-card-title h3 {
  background-color: #d7dedf;
  width: 70%;
  padding: 3%;
  margin: auto;
  border-radius: 100px;
  font-size: 17px;
  font-weight: 500;
}

.report-card-title {
  text-align: center;
}

.report-card-subhead {
  padding: 3%;
  border-bottom: 1px dashed #a7a3a3;
}

span.subhead {
  color: #1fa1c1;
  font-size: 17px;
  font-weight: 500;
  padding: 3%;
  margin-right: 20%;
}

span.subamount {
  font-size: 17px;
  font-weight: 600;
  margin-left: 10%;
}

span.subheadone {
  color: #49bb43;
  font-size: 17px;
  font-weight: 500;
  padding: 3%;
  margin-right: 10%;
}

span.subheadthree {
  color: #d9724a;
  font-size: 17px;
  font-weight: 500;
  padding: 3%;
  margin-right: 16%;
}

.report-card-holder {
  align-items: center;
  margin-top: 21px;
  width: 100%;
  float: right;
  cursor: pointer;
}

/* Reportd End */
/* CSO */
/* .icon_bg {
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
} */

.icon_bg {
  height: 52px;
  width: 52px;
  background-color: #EFF5F6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: medium;
}

.icon_bg .icon_img {
  height: 24px;
  width: 24px;
  background-color: #eff5f6;
  /* margin-top: 25%;
  margin-left: 25%; */
}

.icon_bg .cso_icon_img {
  height: 50%;
  width: 50%;
}

.header_text {
  font-size: 18px;
  line-height: 27px;
  padding: auto;
  margin-top: 3%;
  margin-right: 5%;
}

.top_nav {
  margin: 5% 3% 5% 3%;
  display: flex;
  justify-content: space-around;
}

.sdiv {
  margin-left: 5%;
  margin-bottom: 5%;
}

.stext {
  color: #1fa1c1;
  font-size: 19px;
}

.cname_block_csodemand {
  margin: 5% 5% 25% 0%;
}

.cust_name {
  background-color: #1fa1c1;
  color: white;
  height: 70px;
  width: 278px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  padding-top: 10px;
  padding-left: 5%;
  float: left;
}

.cname {
  text-align: center;
  font-size: 21px;
}

.nach_block {
  float: right;
  background-color: #d2f9d0;
  color: #5bc256;
  height: 58px;
  width: 103px;
  border-radius: 25px;
  padding-top: 12px;
  padding-left: 6%;
}

.nach_text {
  text-align: center;
  font-size: 19px;
}

.info_box {
  background-color: white;
  border-radius: 15px;
  padding-top: 5%;
  padding-left: 3%;
  padding-bottom: 3%;
  width: auto;
  box-shadow: 2px 2px 3px 3px #f1efef;
  text-align: -webkit-center;
}

.cname_loan_info_block {
  background-color: #eff5f6;
  height: 85px;
  width: 95%;
  margin-right: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 5%;
}

.left_side {
  float: left;
}

.left_side>.row {
  padding-top: 5%;
}

.right_side {
  float: right;
  margin-top: 5%;
}

.name_cds {
  font-size: 19px;
  font-weight: 500;
  text-transform: capitalize;
}

.capitalize {
  text-transform: capitalize;
}

img.nach-img {
  margin-right: 2%;
  width: 20px;
}

.loan_id {
  color: #1fa1c1;
  font-size: 18px;
}

.charges_block {
  margin-left: 5%;
  width: 344px;
  /* background-color: red; */
  font-size: 17px;
}

.charges_desc {
  float: left;
  width: 40%;
}

.charges {
  text-align: right;
  margin-right: 17%;
  float: right;
  width: 40%;
}

.separator {
  border-bottom: 1px dashed black;
  width: 90%;
  margin-top: 15%;
  margin-bottom: 5%;
  overflow: hidden;
}

.col_bal_block {
  margin-top: 20%;
  /* background-color: yellow; */
  width: 344px;
  text-align: center;
  font-size: 18px;
  display: flex;
}

.collected {
  float: left;
  width: 48%;
  color: #49bb43;
}

.balanced {
  float: right;
  width: 48%;
  color: #d9724a;
}

.col_block {
  margin-left: 5%;
  padding-top: 8%;
  margin-top: 5%;
  text-align: center;
  background-color: #d2f9d0;
  width: 155px;
  height: 53px;
  border-radius: 15px;
  color: black;
  font-size: 17px;
  margin-bottom: 10%;
}

.bal_block {
  margin-bottom: 10%;
  margin-left: 5%;
  margin-top: 5%;
  padding-top: 8%;
  text-align: center;
  background-color: #fbeee9;
  width: 155px;
  height: 53px;
  border-radius: 15px;
  color: black;
  font-size: 17px;
}

.tot_fail_block {
  background-color: #eff5f6;
  height: 85px;
  width: 95%;
  border-radius: 15px;
  /* display: flex; */
  justify-content: space-around;
  text-align: center;
  font-size: 17px;
  /* padding-top: 8%; */
}

.foot-label {
  padding: 2%;
  text-align: left;
  margin-left: 8%;
  font-size: 20px;
  font-weight: 500;
  color: #4bae4f;
}

.tf_right_side {
  float: left;
  width: 50%;
}

.tf_left_side {
  width: 50%;
  float: left;
}

.tf_right_side {
  float: right;
}

/* CSO END */
/* CSO Transaction History */
.cashid-div {
  background: #e1e1e1;
  width: 15%;
  float: right;
  text-align: center;
  font-weight: 600;
  border-radius: 10px;
  padding: 1%;
}

span.foot-img-holder-reject {
  background-color: #d41616;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-reject img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-approve {
  background-color: #2d9a12;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-approve img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-reject {
  background-color: #f30707;
  display: inline-block;
  width: 23px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-reject img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-submit {
  background-color: #3be911;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-submit img {
  width: 13px;
  height: 15px;
}

span.foot-img-holder-save {
  background-color: #2c22ba;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-save img {
  width: 13px;
  height: 15px;
}

.trans_back_icon_bg {
  background-color: #eff5f6;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 3%;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
}

img.back_icon_img {
  height: 24px;
  width: 24px;
}

.transaction-head-area {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: center;
  padding: 3%;
  margin: 2%;
  border-radius: 10px;
  font-size: 19px;
  font-weight: 500;
  color: black;
}

.month-drop {
  width: 34%;
  float: left;
}

.category-drop {
  width: 33%;
  float: left;
}

.filter-drop {
  width: 33%;
  float: right;
}

.select-data {
  border-radius: 100px;
  padding: 3%;
  font-size: 15px;
  width: 95%;
}

.card-image-holder {
  background-color: #39a3c6;
  /* width: 15%; */
  border-radius: 10px;
  margin-left: 5%;
  float: left;
}

.card-image-holder img {
  width: 55px;
}

.card-text {
  width: 60%;
  float: left;
  margin-left: 5%;
}

.card-text h3 {
  font-size: 18px;
  text-transform: capitalize;
}

.card-amount-area {
  display: inline-flex;
  /* background-color: #EFF5F6; */
  width: 100%;
  /* padding: 3%; */
  color: #20a1c1;
  border-radius: 10px;
  margin-top: 4%;
}

.card-amount-area span {
  font-size: 20px;
  font-weight: 500;
}

span.card-amt {
  width: 70%;
  text-align: end;
}

.card-foot-area {
  padding: 2%;
  margin-top: 3%;
  font-size: 15px;
  font-weight: 300;
  color: #a7a6a6;
}

.date-time-area {
  width: 50%;
  float: left;
  color: #343434;
}

.status-area img {
  width: 5px;
  height: 20px;
}

.status-area {
  text-align: end;
  font-weight: 440;
}

span.foot-img-holder {
  background-color: #f52b2b;
  display: inline-block;
  width: 24px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

span.foot-img-holder-success {
  background-color: #37ab1b;
  display: inline-block;
  width: 24px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

.card-text h2 {
  font-size: 20px;
}

.transaction-card-holder {
  width: 100%;
}

.transaction-header-area {
  margin: 5% 0 0 0;
  height: 70px;
}

/* CSO Transaction History End */

/* Search Household Start */
.name {
  font-size: 18px;
  text-transform: capitalize !important;
  font-weight: 600;
}

.bluetext-header-sort {
  font-size: 17px;
  color: #1fa1c1;
  /* font-weight: bold; */
  margin-left: 5%;
  margin-bottom: 5%;
}

.top_nav {
  margin: 5% 0 5% 0;
  display: flex;
  justify-content: space-around;
}

a>.icon_bg {
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

a>.icon_bg:hover {
  box-shadow: none;
}

a>.icon_img {
  height: 24px;
  width: 24px;
  margin-left: 10%;
  margin-top: 10%;
}

.tot_amt_block {
  margin: auto;
  /* height: 172px; */
  width: 100%;
  border-radius: 25px;
  box-shadow: 1px 1px 2px 2px #cacaca;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.blue_block {
  background-color: #1fa1c121;
  height: 93px;
  width: 107px;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;
}

.bluetext {
  color: #1fa1c1;
  /* padding-top: 30%; */
}

.green_block {
  background-color: #49bb43;
  height: 93px;
  width: 107px;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;
}

.greentext {
  color: #49bb43;
}

.red_block {
  background-color: #d9724a;
  height: 93px;
  width: 107px;
  border-radius: 15px;
  margin-top: 30%;
  padding-top: 20%;
  margin-bottom: 5%;
}

.redtext {
  color: #d9724a;
}

.hh_blue_block {
  background-color: #eff5f6;
  width: 100%;
  /* height: 310px; */
  margin: auto;
  margin-top: 5%;
  border-radius: 25px;
  box-shadow: 1px 1px 2px 2px #cacaca;
  cursor: pointer;
}

.name_hh_block {
  /* background-color: red; */
  padding: 5% 2%;
  margin: 10px;
  margin-bottom: 5px;
  display: flex;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
  /* background-color: #EFF5F6; */
}

.name {
  font-size: 25px;
  text-transform: capitalize;
}

.branch_bluetext {
  font-size: 17px;
  color: #1fa1c1;
}

.hh_id {
  color: #aaa3a3;
  font-size: 17px;
}

.amt_details_block {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.dem_block {
  background-color: #1fa1c121;
  width: 155px;
  height: 53px;
  margin-top: 5%;
  padding-top: 8%;
  color: black;
  border-radius: 15px;
}

.col_block {
  background-color: #2ce22321;
  width: 155px;
  height: 53px;
  margin-top: 5%;
  padding-top: 8%;
  color: black;
  border-radius: 15px;
}

.amt_bal_block {
  margin-top: 2%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.bal_block {
  background-color: #d9724a12;
  width: 155px;
  height: 53px;
  margin-top: 5%;
  padding-top: 8%;
  color: black;
  border-radius: 15px;
}

.hh_white_block {
  background-color: white;
  width: 100%;
  /* height: 310px; */
  margin: auto;
  margin-top: 5%;
  border-radius: 25px;
  box-shadow: 1px 1px 2px 2px #cacaca;
  cursor: pointer;
}

.search_block {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  margin-top: 10%;
  padding-top: 3%;
}

.sort_block {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 2%;
}

.search_elements {
  margin-top: 5%;
}

.search_elements1 {
  margin-top: 3%;
  padding: 15px 10px;
  line-height: 10px;
}

.rinput1 {
  border-radius: 50px;
}

.rinput {
  border-radius: 25px;
}

.rbtn {
  border-radius: 25px;
}

.sortBlueBox {
  width: 100%;
  height: 53px;
  margin: 0 auto;
  background-color: #1fa1c121;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.bluetext-header {
  font-size: 17px;
  color: #1fa1c1;
}

.unique-names {
  overflow: scroll;
  overflow-x: hidden;
  height: 80%;
}

.demDatalist {
  display: flex;
  justify-content: space-between;
  color: red;
  width: 50%;
}

input[type="range"] {
  width: 50%;
}

.unique-name-click {
  cursor: pointer;
}

.reference {
  display: flex;
  justify-content: space-around;
  padding-left: 5%;
}

.refInput {
  width: 90%;
  padding-right: 5%;
}

.refUpload {
  width: 20%;
}

/* Search Household End */
/* Filter window start */
.filter_window {
  /* width: 369px; */
  height: auto;
}

.filter_header {
  float: left;
  width: 44%;
  height: inherit;
  background-color: #eff5f6;
}

.filter_amt {
  float: right;
  height: inherit;
  width: 56%;
  /* box-shadow: 1px 1px 2px 2px #cacaca; */
}

.filter-table {
  font-size: 17px;
}

.main-sec {
  width: 100%;
}

/* .fix-width {
  max-width: 450px;
  
} */

.filter-btn-div {
  display: inline-block;
  bottom: 4%;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* Filter window end */
/* Collection receipt start */
.hhid_holder {
  background-color: #eff5f6;
  padding: 1%;
}

.cr_top_nav {
  margin: 5% 0% 5% 0%;

  height: 70px;

  display: flex;

  justify-content: space-around;

  margin-bottom: 10%;
}

.navadhan_logo_block {
  vertical-align: middle;
  border-style: none;
  background-color: #eff5f6;
  padding: 10%;
  border-radius: 15px;
}

.acen_logo_block {
  background-color: #eff5f6;
  border-radius: 15px;
  padding: 10%;
}

/* .collection-receipt-block {
  
   background-color: white;
  
   box-shadow: 2px 2px 3px 3px #cacaca;
  
   width: 402px;
  
   height: 880px;
  
   border-radius: 25px;
  
   margin: auto;
  
   padding-top: 5%;
  
  } */

.collection_block_header {
  width: 100%;
  text-align: center;
}

.bluetext {
  color: #20a1c1;

  font-size: 20px;
}

.htext2 {
  margin: 2% 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 20px;
  font-weight: 600;
}

.rinput {
  border-radius: 10px;

  /* margin-bottom: 3%; */
}

.loan_disbursement_block {
  background-color: #eff5f6;
  border-radius: 15px;
  text-align: -webkit-center;
  margin: auto;
  box-shadow: 1px 1px 2px 2px #cacaca;
  color: black;
  font-size: 20px;
  padding: 3%;
  width: 100%;
}

.cname_block_pt {
  width: 100%;

  height: 54px;

  /* box-shadow: 1px 1px 2px 2px #cacaca; */

  border-radius: 15px;

  margin-top: 1%;

  padding-left: 5%;
  background-color: #0dbeeb10;
}

.amt_date_block {
  display: flex;

  justify-content: space-around;

  text-align: center;

  margin-top: 3%;

  font-size: 20px;
}

.overdue_demand_block {
  background-color: #eff5f6;
  border-radius: 15px;
  width: 100%;
  margin: auto;
  box-shadow: 1px 1px 2px 2px #cacaca;
  color: black;
  font-size: 20px;
  padding: 3%;
}

.cust_name_pt {
  font-size: 18px;

  float: left;

  width: 70%;

  margin-top: 4%;
}

.call_icon {
  float: right;

  width: 20%;
}

.green_bg {
  background-color: #d2f9d0;
}

.red_bg {
  background-color: #f9531211;
}

.green_text {
  color: #3bc435;
}

.red_text {
  color: #de8765;
}

.dis_block {
  /* background-color: white; */

  border-radius: 15px;

  width: 140px;

  height: 54px;

  /* box-shadow: 1px 1px 2px 2px #cacaca; */

  margin-top: 5%;

  padding-top: 8%;

  font-size: 18px;
}

.total_amt_block {
  display: block;
  margin-top: 4%;
  text-align: -webkit-center;
}

.tamt {
  margin-top: 3%;

  font-size: 20px;
}

/* .new_amt_block {
  
   display: flex;
  
   justify-content: space-around;
  
  } */

.amt_block {
  background-color: #eff5f6;
  padding: 2%;
  border-radius: 15px;
  text-align: center;
  font-size: 19px;
}

.button_block {
  display: flex;

  justify-content: space-around;

  margin-top: 5%;

  margin-bottom: 5%;

  font-size: 20px;
}

/* Collection receipt end */

/* Payment Transfer Start */
.mymodal {
  display: block !important;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s linear;
}

.errmsg {
  color: red;
  font-size: 14px;
  margin-top: 2%;
}

.emi-left {
  font-size: 15px;
  float: left;
  margin-left: 3%;
}

.emi-right {
  font-size: 15px;
  float: right;
}

.payment-method-block {
  background-color: white;
  box-shadow: 2px 2px 3px 3px #cacaca;
  width: 100%;
  height: auto;
  border-radius: 25px;
  margin: auto;
  padding: 2%;
}

.htext {
  margin: 1% 5%;
  font-size: 20px;
}

.pthtext2 {
  /* margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 3%; */
  margin: 5% 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 20px;
}

.poptions {
  display: flex;
  justify-content: space-around;
  margin: 2% 2%;
  padding: 1%;
  font-size: 17px;
}

.pt_total_amt_block {
  display: flex;
  justify-content: space-around;
  margin-top: 6%;
}

.pt_tamt {
  margin-top: 3%;
  font-size: 20px;
}

.pt_amt_block {
  background-color: #eff5f6;
  border-radius: 25px;
  text-align: center;
  padding: 3%;
  font-size: 19px;
}

.pt_button_block {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 20px;
}

.pt-confirm-box {
  width: 386px;
  height: 430px;
  border-radius: 15px;
  margin: auto;
}

.cb-cname {
  font-size: 18px;
  color: #1fa1c1;
  margin: 5% 0% 3% 5%;
}

.cb-cname-box {
  width: 344px;
  height: 53px;
  border-radius: 15px;
  background-color: #1fa1c121;
  padding-top: 4%;
  padding-left: 4%;
  font-size: 17px;
  margin-bottom: 3%;
  margin-left: 2%;
}

.emiCharge {
  display: flex;
  justify-content: space-around;
}

.emi-amt-box {
  width: 165px;
  height: 53px;
  background-color: #1fa1c121;
  border-radius: 15px;
  padding-left: 8%;
  padding-top: 8%;
}

.cb-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
}

/* Payment Transfer End */
/*cash acceptance denom main starts here*/
.ca-header-box {
  margin-top: 29px;
  width: 100%;
  height: auto;
  /*163px;*/
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  font-size: 20px;
  color: #20a1c1;
  padding: 10px;
}

.remarks-inp {
  width: 70%;

  height: 40px;

  border-radius: 10px;

  border: 1px solid grey;

  outline: none;
}

.ca-header-txt-box {
  width: 100%;
  min-height: 58px;
  background-color: #eff5f6;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 45px;
}

.filter-options-container {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.filter-options-container a {
  border: 1px solid #cccaca;
  border-radius: 50px;
  padding: 5px;
  width: 92px;
  height: 44px;
}

.filter-options-container a span {
  font-size: 15px;
  color: #010101;
}

.filter-options-container a img {
  width: 14px;
  height: 14px;
}

.cso-list-container {
  width: 100%;
  height: auto;
}

.cso-card-container {
  margin-top: 29px;
  width: 100%;
  min-height: 185px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  padding-bottom: 10px;
}

.card-header-container {
  width: 100%;
  min-height: 58px;
  background-color: #eff5f6;
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.card-header-container span {
  font-size: 20px;
  color: #20a1c1;
  max-width: 170px;
}

.card-header-container .date-time span {
  color: #818485;
  font-size: 17px;
}

.amt-container {
  min-width: 176px;
  height: 80px;
  margin-top: 13px;
}

.amt-container p {
  font-size: 18px;
  font-family: Montserrat;
  color: #010101;
}

.amt-container .bal-amount {
  width: 160px;
  height: 50px;
  padding: 15px;
  background-color: rgba(44, 226, 35, 0.12);
  border-radius: 10px;
  color: #15ae10;
  font-size: 18px;
}

.amt-container .hnd-amount {
  width: 160px;
  height: 50px;
  padding: 15px;
  background-color: #f8eeea;
  border-radius: 10px;
  color: #d57551;
  font-size: 18px;
}

.sort_block_2 {
  background-color: white;
  border-radius: 25px;
  height: auto;
  margin: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  padding-left: 2%;
}

.filter_window_1 {
  height: 500px;
}

.month-filter {
  overflow: auto;
}

/*cash acceptance denom main end here*/
/*cash acceptance denom starts here*/
textarea.remarks-inp {
  width: -webkit-fill-available;
  height: auto;
}

.ca-dn-card {
  margin-top: 29px;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ca-header-txt-box {
  color: #20a1c1;
}

.dn-container {
  width: 100%;
}

.dn-row {
  width: 100%;
  margin: 11px;
}

.dn-row a {
  cursor: pointer;
}

.dn-row-box {
  width: 100px;
  height: 45px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dn-header-text {
  color: #20a1c1;
  font-weight: 450;
  font-size: 18px;
  font-family: Montserrat;
}

.dn-gre-amt {
  background-color: rgba(44, 226, 35, 0.21);
  border-radius: 8px;
  font-size: 18px;
}

.dn-red-amt {
  background-color: rgba(217, 114, 74, 0.21);
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
}

.dn-pri-amt {
  background-color: #eff5f6;
  border-radius: 8px;
  font-size: 18px;
}

.dn-total {
  min-width: 100px;
  background-color: #eff5f6;
  border-radius: 8px;
  height: 45px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fn-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.fn-button img {
  width: 20px;
  height: 20px;
}

.ca-submit-btn {
  width: 188px;
  height: 59px;
  background-color: #1fa1c1;
  border-radius: 47px;
  margin-top: 29px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*cash acceptance denom end here*/
/* Deposit Money Start */
.back_icon_bg {
  border-radius: 50%;
  height: 52px;
  width: 52px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff5f6;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
}

.back_icon_img_dm {
  height: 24px;
  width: 24px;
}

.bluetext_1 {
  color: #20a1c1;
  font-size: 19px;
  font-weight: 500;
  /* margin-right: 100px; */
}

.bluetext_2 {
  color: #20a1c1;
  padding-top: 30%;
  font-size: 19px;
  font-weight: semiBold;
  font-weight: normal;
  text-align: center;
}

.bluetext_3 {
  color: #000000;
  font-size: 19px;
  font-weight: semiBold;
  font-weight: normal;
  text-align: center;
}

.collection_margin {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 5%;
  width: 100%;
}

.collection-receipt-block {
  background-color: white;
  box-shadow: 2px 2px 3px 3px #cacaca;
  width: 100%;
  height: auto;
  border-radius: 25px;
  padding: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cash_handover_main {
  margin-top: 3%;
  background-color: #eff5f6;
  width: 95%;
  height: 64px;
  margin: 2%;
  border-radius: 5px;
  padding-right: 5%;
  padding-left: 5%;
  display: flex;
  align-items: center;
}

.cash_handover_margin {
  display: flex;
  justify-content: space-around;
  text-align: center;
  width: 100%;
  /* padding-left :80px; */
  border-radius: 5px;
}

.cash_handover_header {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  font-size: 19px;
  font-weight: semiBold;
  border-radius: 5px;
  /*width: 220px;
    height: 50px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.cash_details_block {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding-top: 40px;
}

.cash_block_1 {
  text-align: left;
  padding-top: 5%;
  font-size: 17px;
  font-weight: 400;
  width: 189px;
}

.amt_rs_block_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eff5f6;
  min-width: 114px;
  min-height: 59px;
  overflow: hidden;
  border-radius: 5px;
  font-size: 19px;
}

.amt_rs_block_11 {
  color: #48c4c4;
  font-size: 17px;
  font-weight: 500;
}

.cash_details_block_2 {
  display: flex;
  margin-top: 5%;
  justify-content: space-between;
  text-align: center;
}

.cash_block_2 {
  text-align: left;
  padding-top: 5%;
  font-size: 17px;
  font-weight: 400;
  width: 190px;
}

.amt_rs_block_22 {
  color: #ef8053;
  font-size: 17px;
  font-weight: 500;
}

.total_block {
  margin-top: 5%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

/*for image margin and position*/
.img_margin_1 {
  margin-top: 9%;
}

.img_margin_2 {
  margin-top: 3%;
}

/*for image -circle*/
.top_nav_1 {
  margin: 5% 3% 5% 3%;
  display: flex;
  justify-content: space-around;
  padding-top: 3%;
}

.icon_img_1 {
  height: 24px;
  width: 24px;
  margin-left: 10%;
  margin-top: 10%;
}

.green_block_1 {
  background-color: #2ce22333;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5%;
  padding-top: 8%;
  border-radius: 5px;
  text-align: center;
  border: none;
}

.red_block_1 {
  background-color: #ddd9724a;
  width: 90px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: none;
  margin-left: 1%;
}

.red_block_2 {
  background-color: #ddd9724a;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
}

.blue_block_1 {
  background-color: #eff5f6;
  width: 110px;
  min-height: 45px;
  color: black;
  margin-top: 5%;
  padding-top: 8%;
  border-radius: 5px;
  text-align: center;
  border: none;
  margin-left: 1%;
  overflow: hidden;
}

.total_margin_2 {
  text-align: center;
  width: 100%;
}

.total_block_2 {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.total_margin_3 {
  width: 100%;
}

.total_block_3 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.total_margin_4 {
  width: 100%;
  text-align: center;
}

.total_block_4 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.total_margin_5 {
  width: 100%;
  text-align: center;
}

.total_block_5 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.total_margin_6 {
  width: 100%;
  text-align: center;
}

.total_block_6 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.total_margin_7 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.total_block_7 {
  margin-top: 2%;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/*for giving space between top to bottom*/
.total_margin_8 {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

/*for moving space to forward (next to current block)*/
.total_block_8 {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex: 2 1;
}

.total_cash {
  font-size: 19px;
  font-weight: semiBold;
  border-radius: 100px;
  text-align: center;
  width: 220px;
  height: 50px;
  color: black;
  display: flex;
  align-items: center;
}

/*for save button*/
.button_block {
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 100px;
}

/* for rounded button */
.rbtn {
  border-radius: 25px;
}

/* Deposit Money End */
/* Collection Success Start */

.back-icon {
  width: 30px;
  height: 30px;
}

.back-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eff5f6;
}

.header-message {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: medium;
}

.date-time {
  font-family: Montserrat;
  color: #000000;
}

.paid-to-card {
  background-color: white;
  border-radius: 20px;
  height: 189px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.card-box-header {
  height: 64px;
  background-color: #eff5f6;
  border-radius: 12px;
  font-size: 19px;
  font-family: Montserrat;
  color: #20a1c1;
  font-weight: 500;
}

.customer-name-text {
  color: black;
  font-family: Montserrat;
  font-weight: semibold;
  font-size: 20px;
}

.amount-paid-text {
  color: #2da7c5;
  font-weight: medium;
  font-size: 20px;
}

.transfer-details-card {
  background-color: white;
  border-radius: 20px;
  height: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.transaction-id-text {
  color: #838687;
  font-weight: medium;
  font-size: 18px;
}

.transaction-id {
  color: black;
  font-family: Montserrat;
  font-weight: semibold;
  font-size: 18px;
}

.copy-button {
  height: 51px;
  width: 95px;
  border-radius: 32px;
  background-color: #eff5f6;
  font-size: 19px;
  font-family: Montserrat;
  color: #20a1c1;
  font-weight: semibold;
}

.debited-from-text {
  color: #838687;
  font-weight: medium;
  font-size: 18px;
}

.section-break-line {
  color: #a7a3a3;
  border-top: 1px dashed #a7a3a3;
}

.contact-card {
  background-color: white;
  border-radius: 20px;
  height: 65px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
  font-size: 20px;
  font-family: Montserrat;
}

.account-number {
  color: black;
  font-weight: medium;
  font-size: 18px;
  font-family: Montserrat;
}

.utr-number {
  color: black;
  font-weight: medium;
  font-size: 18px;
  font-family: Montserrat;
}

.nav-icons-footer-text {
  color: #1fa1c1;
  font-size: 15;
  font-family: Montserrat;
}

.profile-icon {
  width: 71px;
  height: 71px;
  background-color: #2ab4f5;
  border-radius: 50%;
  font-size: 30px;
  font-family: Montserrat;
  color: white;
  font-weight: bold;
}

.bank-logo {
  width: 66px;
  height: 64px;
  background-color: #eff5f6;
  border-radius: 12px;
}

.logo-box {
  width: 60px;
  height: 60px;
  background-color: #1fa1c1;
  border-radius: 16px;
}

/* Collection Success End */
/*Collection Success - Vaibhav Changes*/
.click_icon {
  cursor: pointer;
}

.message-box {
  background-color: white;
  border-radius: 25px;
  height: 150px;
  margin: auto;
  margin-top: 10%;
  padding-top: 5%;
}

.red_block_2 {
  background-color: #ddd9724a;
  width: 100px;
  height: 45px;
  color: black;
  margin-top: 5px;
  padding-top: -4px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid black;
}

/*Collection Success - Vaibhav Changes*/
/* Logout Start */
.icon_logout {
  float: right;
  display: none;
  /* display: inline-block; */
}

img.icon_logout_img {
  height: 40px;
  width: 40px;
  margin-left: 10%;
  margin-top: 6%;
  position: relative;
}

/* Logout End */
/* CashDepositeBank Start */
.list_img {
  background-color: #eff5f6;
  padding: 29%;
  border-radius: 50px;
  margin-left: 5%;
  width: 60px;
  height: 60px;
}

.filter-btn {
  display: flex;
}

span.cash-id {
  float: right;
}

.list_img_right {
  background-color: #eff5f6;
  padding: 3%;
  border-radius: 50px;
  margin-right: 5%;
  float: right;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
}

.card-label {
  width: 50%;
  display: inline-block;
  padding: 2%;
}

.card-amtlabel {
  float: right;
  width: 30%;
  text-align: center;
  background-color: #2ce22321;
  border-radius: 15px;
  padding: 2%;
}

.card-amtdep {
  float: right;
  width: 30%;
  text-align: center;
  background-color: #f8eeea;
  border-radius: 15px;
  padding: 2%;
}

.file-upload {
  margin: 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  color: black;
  background-color: #eff5f6;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.2);
}

.file-upload:hover {
  box-shadow: none;
}

.view-img {
  width: 50%;
  display: inline-block;
}

.remove-img {
  width: 50%;
  display: inline-block;
}

.file-upload img {
  width: 35px;
  padding: 1%;
}

.fileupload-holder {
  box-shadow: 0px 5px 6px #e1e1e1;
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  margin-top: 3%;
}

.csoname-holder {
  background-color: #eff5f6;
  border-radius: 15px;
  padding: 2%;
  margin-bottom: 3%;
  text-transform: capitalize;
}

span.cbfoot-img-holder-submit {
  background-color: #37ab1b;
  display: inline-block;
  width: 25px;
  text-align: center;
  border-radius: 100px;
  margin-left: 2%;
}

.cdb-confirm-box {
  width: auto;
  height: auto;
  border-radius: 15px;
  margin: auto;
  padding: 4%;
}

.pdfviewfile {
  height: 400px;
}

.popupdownload-btn {
  text-align: center;
  padding: 3%;
}

/* CashDepositeBank End */

.log-out {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #EFF5F6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.log-out1 {
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 2px rgb(0 0 0 / 20%);
}

.log-out img {
  width: 50%;
  height: 50%;
  border-radius: 50px;
  margin: 0;
}

.log-out1 img {
  height: 30px;
  width: 30px;
  background-color: #eff5f6;
  margin-top: 20%;
  margin-left: 5%;
}

.log-out-deposit {
  float: right;
}

.log-out-deposit img {
  width: 64%;
  background-color: #eff5f6;
  padding: 8%;
  border-radius: 50px;
  margin-top: 25%;
  float: left;
}

.log-out-history {
  float: right;
}

.log-out-history img {
  width: 64%;
  background-color: #eff5f6;
  padding: 8%;
  border-radius: 50px;
  margin-top: 30%;
  float: left;
}

.log-out-report {
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0px 2px rgb(0 0 0 / 20%);
  float: right;
}

.log-out-report img {
  /* width: 64%;
  background-color: #EFF5F6;
  padding: 8%;
  border-radius: 50px;
  float: left;
  margin-top: 7%; */
  height: 25px;
  width: 25px;
  background-color: #eff5f6;
  margin-top: 25%;
  margin-left: 25%;
}

/* To Slip Verification */
span.verify-foot-text {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

input.recon-inp {
  width: 100%;
  border-radius: 5px;
  margin-top: 2%;
  border: 1px solid;
}

.foot-button {
  width: 100%;
  margin: 2%;
  text-align: center;
}

/* To Slip Verification End */
/* NACH Type Start */
.webkitcenter {
  text-align: -webkit-center;
}

.pnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #10928b;
}

.pnachimage-holder img {
  padding: 17%;
  margin-left: 7%;
}

.nbnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #0178b7;
}

.nbnachimage-holder img {
  padding: 17%;
}

.dcnachimage-holder {
  text-align: center;
  width: 30%;
  border-radius: 100px;
  background-color: #ab720f;
}

.dcnachimage-holder img {
  padding: 17%;
}

/* NACH Type End */
/* NACH Register form start */
img.pnachreg-img {
  width: 30px;
}

.nachreg_icon_bg {
  background-color: rgb(31 161 193) !important;
}

/* NACH Register form end */
.support_form_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.support_form_body form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.support_form {
  width: 100%;
}

.support_form select {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  background-color: inherit;
  color: rgb(123, 123, 123);
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.support_form input[type="text"] {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 5px;
  background-color: inherit;
}

.support_form textarea {
  width: 90%;
  min-height: 150px;
  border-radius: 11px;
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background-color: inherit;
}

.support_form .support_file_upload {
  width: 90%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  height: 53px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.support_form .support_file_upload span {
  color: rgb(123, 123, 123);
  margin-left: 4px;
}

.support_form .support_file_upload input {
  display: none;
}

/* NACH Success start */
.nach_suc_title h2 {
  font-size: 18px;
  padding: 3%;
}

.nach_suc_title h2 span {
  color: #1fa1c1;
}

.nach_suc_title h2 img {
  width: 25px;
}

.success-homebtn img {
  width: 25px;
  padding: 2%;
}

.success-homebtn button {
  border-radius: 100px;
  font-size: 20px;
  font-weight: 400;
}

.pop-title {
  font-size: 20px;
  padding: 2%;
  /* text-align: -webkit-center; */
}

.nach-img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  padding: 2%;
  text-align: center;
  justify-content: center;
  margin-right: 5px;
}

.nachreg_icon_bg {
  background-color: #ddd4ca !important;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 4%);
}

.icnsize {
  width: 70%;
}

.stsr {
  float: right;
  color: #fff;
  background-color: red;
  padding: 5px;
  border-radius: 10px;
  font-size: 15px;
}

/* NACH Success End */
thead {
  background-color: #eff5f6;
  font-size: 16px;
  font-weight: 400;
}

img.add-new {
  width: 50%;
  margin: auto;
}

.updownimg {
  display: flex;
  width: auto;
  float: left;
  margin-top: 2%;
  /* margin: 2% 1%; */
}

img.add-new {
  /* width: 50px;
  margin: 4%;
  background: #ebedef;
  padding: 6%;
  border-radius: 50px;
  height: 50px;
  text-align: -webkit-center;
  justify-items: center;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 9%); */
  width: 50px;
  height: 50px;
}

img.add-new:hover {
  cursor: pointer;
}

#findash tr {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 9%);
  border: none;
  background: none;
  font-size: 16px;
  height: 5rem;
}

#findash {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: separate;
  border-spacing: 0 1em;
  border-radius: 15px;
}

.loading {
  text-align: -webkit-center;
  width: 100%;
}

.loading img {
  width: 30%;
}

.labelbg {
  padding: 3%;
  text-align: -webkit-center;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  min-width: 100px;
}

.ibg {
  background-color: #8f9397;
}

.rbg {
  background-color: #63ca3c;
}

.pbg {
  background-color: #02a6fc;
}

.jbg {
  background-color: #f6341d;
}

.sbg {
  background-color: #cab23c;
}

.ubg {
  background-color: #ca3ca4;
}

select.form-control.input-sm {
  width: 100px;
}

.nach-file-label {
  visibility: hidden;
}

input[type="checkbox"].loanswitch_1 {
  font-size: 20px;
  appearance: none;
  width: 2em;
  height: 0.8em;
  background: #1fa1c1;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin-top: 0%;
}

input[type="checkbox"].loanswitch_1:after {
  position: absolute;
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
  transform: scale(0.7);
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].loanswitch_1:checked {
  background: #1fa1c1;
}

input[type="checkbox"].loanswitch_1:checked:after {
  left: calc(100% - 0.8em);
}

.start_date {
  background-color: #eff5f6;
  text-align: center;
  border: none;
  font-size: 20px;
}

input#start_date::-webkit-input-placeholder {
  color: #39a3c6;
}

.end_date {
  background-color: #f7efec;
  text-align: center;
  border: none;
  font-size: 20px;
}

input#end_date::-webkit-input-placeholder {
  color: #d57551;
}

.lbl25 {
  font-size: 25px;
}

.fileupload-holder {
  /* box-shadow: 0px 5px 6px #e1e1e1; */
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  margin-top: 3%;
}

.file-upload {
  margin: 0 auto;
  width: auto;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  color: black;
  gap: 5%;
}

.file-upload img {
  width: 50%;
}

.nach-fileupload-holder {
  box-shadow: 0px 5px 6px #e1e1e1;
  border-radius: 10px;
  padding: 2%;
  text-align: center;
  margin-top: 3%;
  text-align: left;
}

.upload-img {
  text-align: center;
}

.upload-img:hover {
  cursor: pointer;
}

.loan_data:hover {
  cursor: pointer;
}

.border-b2 {
  border-bottom: 1px solid #70707021;
  padding: 2% 0;
}

.upload-img span {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.nach-upldbtn {
  text-align: center;
  vertical-align: bottom;
  padding: 3%;
}

.upldbtn {
  width: 80%;
  font-size: 20px;
}

span.upldinst {
  font-size: 16px;
  font-weight: 500;
}

.diffcol {
  background-color: red;
}

/* nach preparation for pull start */
button.pull-updbtn {
  position: absolute;
  right: 10%;
  font-size: 16px;
  color: #fff;
  text-align: center;
  transform: translate(-60%, 35px);
}

.pill-name {
  font-size: 20px;
  padding: 2%;
}

.pull-dataholder span {
  font-size: 15px;
  width: 100%;
  float: left;
}

.nach-pull td {
  vertical-align: middle;
}

.next-pullamt {
  text-align: center;
  padding: 10%;
  background: #d2f9d0;
  border-radius: 5px;
  height: 20px;
}

.next-pulldate {
  text-align: center;
  padding: 10%;
  background: #dbe2f3;
  border-radius: 5px;
  height: 20px;
}

.pullab-holder {
  text-align: center;
  font-size: 20px;
  background: #eff5f6;
  padding: 3%;
  border-radius: 5px;
}

.pullab-holder span {
  color: #1fa1c1;
}

.pull-upd {
  padding: 5%;
  text-align: center;
  font-size: 18px;
}

.nextpull-amtupd {
  background: #d2f9d0;
  padding: 5%;
  border-radius: 5px;
}

.nextpull-flagupd {
  background: #f3eddb;
  padding: 5%;
  border-radius: 5px;
}

.nextpull-dateupd {
  background: #dbe2f3;
  padding: 5%;
  border-radius: 5px;
}

.pullloanradio_input {
  margin-top: 5%;
}

.tabs-data li {
  font-size: 20px;
  font-weight: 600;
}

/* nach preparation for pull end */
/* spin start */
.loader {
  /* Default loader start to hide default comment below code */
  border: 16px solid #d4d4d4;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  /* Default loader end */
  background-color: transparent;
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999999;
  /* show image in loader start */
  /* background-image: url('../images/acen_Logo.png');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%; */
  /* show image in loader end */
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgb(23 22 22 / 39%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.page-content {
  max-width: 768px;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* spin end */
.filter-date {
  margin-top: 17%;
}

/*chat Btn*/
.chatSupportBtn {
  all: unset;
  position: fixed;
  bottom: 4%;
  right: 7%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background-color: #1fa1c1;
  cursor: pointer;
  float: right;
  user-select: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatSupportBtn:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.chatSupportBtn:focus {
  outline: none;
}

.chatSupportBtn img {
  width: 80%;
  height: 80%;
}

/* chat btn end */
/* support ticket form */
.support_nav_section {
  background-color: #23a3e1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: auto;
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.support_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.support_navbar span {
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.support_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.support_navbar .button:hover {
  background-color: #ddebee;
  transition: all 0.3s ease-out;
}

.support_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.support_navbar .button img {
  width: 50%;
}

.support_form_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.support_form_body form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.support_form {
  width: 100%;
}

.support_form select {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  background-color: inherit;
  color: rgb(123, 123, 123);
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.support_form input[type="text"] {
  width: 90%;
  height: 53px;
  border-radius: 11px;
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 5px;
  background-color: inherit;
}

.support_form textarea {
  width: 90%;
  min-height: 150px;
  border-radius: 11px;
  outline: #1fa1c1;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background-color: inherit;
}

.support_form .support_file_upload {
  width: 90%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  height: 53px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.support_form .support_file_upload span {
  color: rgb(123, 123, 123);
  margin-left: 4px;
}

.support_form .support_file_upload input {
  display: none;
}

.support_form .st_audio_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 10px;
  padding: 5px;
}

.support_form .audio-controls {
  display: flex;
  gap: 4px;
}

/* support ticket form */

/* support ticket list */
.support_ticket_list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}

.support_list_navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.support_list_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.support_list_navbar .button:hover {
  background-color: #ddebee;
  transition: all 0.3s ease-out;
}

.support_list_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.support_list_navbar .button img {
  width: 50%;
}

.support_list_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.support_list_filters button {
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0rem 0.6rem;
  gap: 1rem;
  border-radius: 11px;
  height: 45px;
  flex-grow: 0;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.support_list_filters button:active {
  background-color: whitesmoke;
}

.support_filter_menu_cnt li:hover {
  background-color: whitesmoke;
}

.support_list_search_box {
  width: 100%;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}

.support_list_search_box span {
  color: #1fa1c1;
  font-size: 19px;
  font-weight: 500;
  width: 100%;
}

.support_list_search_box input {
  width: 100%;
  border-radius: 50px;
  height: 53px;
  padding-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.support_list_ticket_preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding: 15px 19px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.support_list_ticket_summary {
  font-size: 15px;
  width: 90%;
  text-align: justify;
  font-weight: 500;
}

.support_list_ticket_created_at {
  color: #a19f9f;
  font-size: 14px;
}

.support_list_ticket_status {
  width: 100px;
  height: 28px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #8f9397;
  border-radius: 5px;
  padding: 0px 5px;
}

.ticket_status_default {
  background-color: #8f9397;
}

.ticket_status_resolved {
  background-color: #63ca3c;
}

.ticket_status_inwork {
  background-color: #ff8b03;
}

.ticket_status_inqueue {
  background-color: #02a6fc;
}

/* support ticket list */

/* support ticket */
.support_ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.support_ticket_details {
  margin-top: 15px;
  background: white;
  border-radius: 13px;
  min-height: 400px;
  width: 100%;
  padding: 18px 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.support_ticket_created_at {
  color: #a19f9f;
  font-size: 14px;
}

.support_ticket_status {
  width: 100px;
  height: 28px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0px 5px;
}

.support_ticket_summary {
  font-size: 15px;
  width: 85%;
  font-weight: 500;
  color: #2ca6c4;
}

.support_ticket_ctpr {
  font-size: 15px;
  padding: 0.5em 1.4em;
  background-color: #eff5f6;
  border-radius: 8px;
  word-wrap: break-word;
  max-width: 60%;
}

.support_ticket_description {
  width: 85%;
  margin: 0 auto;
  font-size: 16px;
}

.support_ticket_attachment_cnt {
  display: flex;
  width: 85%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.support_ticket_attachment {
  width: 155px;
  background: whitesmoke;
  height: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.attachment_image {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}


.attachment_image::after {
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
}

.attachment_image:hover::after {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s ease-in;
}

.attachment_image img {
  width: 100%;
  margin: auto;
}

.attachment_label {
  padding: 10px;
  background-color: white;
  text-decoration: none;
  font-size: 14px;
  width: 100%;
}

.attachment_label div {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.click_to_download {
  font-size: 14px;
  font-weight: 500;
  color: lightblue;
  cursor: pointer;
  text-decoration: underline;
}

.support_ticket_heading {
  font-weight: 500;
  font-size: medium;
  margin: 10px 0;
}

.support_ticket_comment_heading {
  display: flex;
  gap: 10px;
  width: 100%;
}

.support_ticket_comment_heading .comment_author {
  font-size: 15px;
  font-weight: 500;
}

.support_ticket_comment_heading .comment_time {
  font-size: 13px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
}

.support_ticket_comment .comment_body {
  font-size: 16px;
  margin-left: 20px;
}

.support_ticket_comment {
  width: 100%;
  margin-left: 20px;
}

.support_ticket_comment_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.support_ticket_add_comment {
  width: 100%;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.support_ticket_add_comment_legend {
  width: 40px;
  height: 40px;
  font-size: small;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: 1.5em;
  background-color: #6579fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_ticket_add_comment_form {
  width: 95%;
  position: relative;
}

.support_ticket_comment_action {
  position: absolute;
  display: flex;
  gap: 5px;
  right: 0;
  margin: 2px;
}

/* support ticket */
img.mailicn {
  width: 70%;
}

/* Household List New Design Start */
.card-holder {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
  padding: 4%;
  position: relative;
  margin-top: 2%;
}

.card-holder-disb {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
  padding: 2%;
  position: relative;
  margin-top: 2%;
}

.card-name-holder {
  margin: 2% 0;
}

.card-name {
  color: #1467c5;
  font-size: 16px;
  font-weight: 600;
}

.card-hhid {
  font-size: 14px;
  color: #070707;
}

.nachbranch-holder {
  padding: 4% 0;
}

.nachbranch-holder span {
  font-size: 14px;
  color: #1467c5;
  background: #ebf3fc;
  padding: 12px;
  border-radius: 20px;
}

.branch-holder span {
  font-size: 14px;
  color: #1467c5;
  background: #ebf3fc;
  padding: 12px;
  border-radius: 20px;
  width: 48%;
  float: left;
  text-align: center;
  margin: 1%;
}

.branch-holder {
  border-bottom: 1px solid #70707021;
  padding: 4% 0;
  display: flex;
}

.loan-detail {
  border-bottom: 1px solid #70707021;
  padding: 3% 0;
}

.bank-label {
  font-size: 14px;
  color: #1467c5;
  margin: 3% 0;
}

.bank-detail {
  background-color: #eef5fd;
  border-radius: 10px;
  padding: 4%;
  position: relative;
  margin-top: 2%;
  font-size: 14px;
}

.bankdetail-data {
  padding: 1%;
}

.tb-border {
  border-top: 1px solid #70707021;
  border-bottom: 1px solid #70707021;
  padding: 2% 1%;
}

.button-holder {
  background: #1467c5;
  margin-top: 3%;
  border-radius: 0 0 20px 20px;
  position: inherit;
  bottom: -13px;
}

.sms-email-holder {
  display: flex;
  float: right;
  /* margin-top: 2%; */
}

.sms-email-holder-align {
  float: none !important;
}

.sms-holder {
  background: #e94e29;
}

.email-holder {
  background: #cf941f;
}

/* Household List New Design End */

.maintaenance-text {
  color: red;
}

.maintaenance-container {
  text-align: center;
  background-color: #fff;
  width: 300px;
  margin: 0 auto;
  padding: 3em 1em 1em;
  position: relative;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.maintaenance-image {
  width: 180px;
  margin: 0 auto;
}

/* Create NACH POPUP Start */
.loanid-holder {
  padding: 3%;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 1%;
}

.loanid-holder span {
  font-size: 16px;
  font-weight: 600;
  color: #1467c5;
}

.loandata-border {
  border-top: 1px solid #70707021;
  border-bottom: 1px solid #70707021;
  padding: 2% 0;
  margin: 2% 0;
}

.loan-dataholder {
  margin: 2%;
  background: #eef5fd;
  padding: 3%;
  font-size: 15px;
  border-radius: 10px;
}

select.selnach {
  background: #f1c39c;
  border: 1px solid #ab560e;
  font-size: 14px;
  color: #000000;
  height: 50px !important;
}

.regbtn-holder {
  text-align: center;
  margin: 3% 0 0 0;
}

button.regbtn {
  background: linear-gradient(#114b8d, #1467c5);
  color: #fff;
  padding: 2%;
  width: 25%;
  border: 1px solid #0a3464;
  border-radius: 5px;
}

.selpartnergateway {
  background: #cbfcb3;
  font-size: 14px;
  color: #000000;
  border: 1px solid #49bc11;
  margin: 1% 0;
  height: 50px !important;
}

.selpartner > .ant-select-selector{
  align-items: center !important;
  background: #cbfcb3 !important;
  font-size: 14px !important;
  color: #000000 !important;
  border: 1px solid #49bc11 !important;
  /* margin: 1% 0; */
  height: 50px !important;
}

.regbtnopacity {
  opacity: 0.7;
}

/* Create NACH POPUP End */

/* Download New Design Start */
img.searchbtn {
  background: #0ca8a8;
  height: 40px;
  padding: 10%;
  border-radius: 5px;
}

img.searchbtn-nach {
  height: 40px;
  cursor: pointer;
}

img.clearcookiebtn {
  height: 40px;
  cursor: pointer;
}

.filter-holder {
  margin-top: 3%;
}

.data-holder {
  margin-top: 3%;
}

.card-data {
  margin-top: 4%;
  width: 100%;
  min-height: 185px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 12%) -3px 3px;
  padding-bottom: 10px;
}

.head-data {
  background: #1467c5;
  color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 3%;
}

.loan-data {
  margin-top: 2%;
  padding: 3%;
}

.head-lid {
  font-size: 16px;
  font-weight: 600;
}

.head-hhid {
  font-size: 14px;
}

.partner-nm {
  font-size: 15px;
  border-bottom: 1px solid #707070;
  padding-bottom: 3%;
}

.action-label {
  font-size: 16px;
  font-weight: 600;
  margin: 2% 0;
  padding: 0;
}

button.kycbtn {
  background: linear-gradient(#f6683e, #ad3e1e);
  color: #fff;
  padding: 4%;
  border: none;
  border-radius: 5px;
  text-align: center;
  min-height: 50px;
}

button.partnerbtn {
  background: linear-gradient(#9e6bd2, #6f4a96);
  color: #fff;
  padding: 4%;
  border: none;
  border-radius: 5px;
  text-align: center;
  min-height: 50px;
}

button.loanbtn {
  background: linear-gradient(#36c224, #1d7f11);
  color: #fff;
  padding: 4%;
  border: none;
  border-radius: 5px;
  text-align: center;
  min-height: 50px;
}

.action-btn.container.row .col-4 {
  padding-left: 0;
}

/* Download New Design End */
/* Overdue New Design Start */
.hhdetails {
  padding: 3%;
}

.hhdetails h2 {
  color: #1467c5;
  font-size: 17px;
}

.amtdataholder {
  margin-top: 3%;
  border: 1px solid #bfbfbf;
  padding: 2%;
  text-align: center;
  border-radius: 10px;
}

.amt-label {
  font-size: 15px;
  height: 50px;
  vertical-align: middle;
  line-height: normal;
  justify-content: center;
  display: flex;
  align-items: center;
}

.amt-emi {
  background: #f9e9e9;
}

.amt-pull {
  background: #d2f9d0;
}

.amt-current {
  background: #f9e9e9;
}

.amtdata {
  padding: 8%;
  border-radius: 5px;
}

button.updbtn {
  background: linear-gradient(#2789f8, #0b56ac);
  padding: 2%;
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  width: 20%;
}

.updbutton {
  text-align: center;
  padding-top: 3%;
}

.pulamt {
  text-align: center;
}

.pulamt span {
  background: #7ca8d9;
  padding: 5%;
  border-radius: 5px;
  color: #fff;
}

.pullamt-label {
  margin-top: 3%;
  color: #03ffff;
}

.operlabel {
  color: #030303;
  font-size: 17px;
}

button.opebtn {
  padding: 7%;
  width: 80%;
  border: none;
  border-radius: 5px;
}

button.deact {
  background: #e9102a;
  color: #ffffff;
}

button.aprve {
  background: #089d4d;
  color: #ffffff;
}

button.rjact {
  background: #c99807;
  color: #ffffff;
}

/* Overdue New Design End */
/* New To Slip Start */
.toslip {
  background: linear-gradient(#3e75cb, #1c4788);
}

.back_button_toslip {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.back_button_toslip {
  background-color: #ddebee;
  transition: all 0.3s ease-out;
}

.back_button_toslip {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.back_button_toslip img {
  width: 50%;
}

.image-holder-toslip {
  text-align: center;
}

.image-holder-toslip img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.nachreg {
  background: linear-gradient(#8e45d9, #4a1b7a);
}

.image-holder-nachreg {
  text-align: center;
}

.image-holder-nachreg img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.down {
  background: linear-gradient(#f1653c, #af3e1c);
}

.image-holder-down {
  text-align: center;
}

.image-holder-down img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.puldown {
  background: linear-gradient(#2faeae, #0e7a7a);
}

.image-holder-puldown {
  text-align: center;
}

.image-holder-puldown img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.prepull {
  background: linear-gradient(#35c223, #1c7e10);
}

.image-holder-prepull {
  text-align: center;
}

.image-holder-prepull img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.failcase {
  background: linear-gradient(#d89629, #97681b);
}

.image-holder-failcase {
  text-align: center;
}

.image-holder-failcase img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.ndeac {
  background: linear-gradient(#dc3a3a, #951818);
}

.image-holder-ndeac {
  text-align: center;
}

.image-holder-ndeac img {
  background-color: rgba(246, 246, 246, 0.27000001072883606);
  border-radius: 100px;
  padding: 10px;
}

.whitefont h2 {
  color: #ffffff;
}

.adatcol {
  color: #b70b0b;
}

.apratcol {
  color: #18b118;
}

.fail_reson {
  padding: 2%;
  border-radius: 5px;
  background: #f9e9e9;
}

.fail_nachsts {
  padding: 2%;
  border-radius: 5px;
  background: #0bb73309;
  text-align: center;
  font-size: 1.4em;
  color: #5c8f66;
}

/* New To Slip End */

/* UTR */
.utr_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.utr_navbar h3 {
  margin: 0px;
}

.utr_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.utr_navbar .button:hover {
  background-color: #ddebee;
  transition: all 0.3s ease-out;
}

.utr_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.utr_navbar .button img {
  width: 50%;
}

.utr_main_block {
  margin-top: 1rem;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding-top: 25px;
  padding-bottom: 5px;
  padding-inline: 10px;
}

.utr_action_block {
  padding-left: 10px;
  padding-right: 10px;
}

.utr_action_button_block {
  display: flex;
  justify-content: space-between;
}

.utr_action_block_header {
  display: flex;
  justify-content: space-between;
}

.utr_table_block {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.utr_rows_count {
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
}

.utr_data_table {
  width: 100%;
}

.utr_data_table thead {
  position: sticky;
  top: 0;
}

.utr_data_table th {
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #29545f;
  font-size: small;
}

.utr_data_table tr {
  background-color: #fcfcfc;
}

.utr_data_table tr:nth-child(2n-1) {
  background-color: #eceded;
}

.utr_data_table td {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  font-size: smaller;
}

.utr_upload_success {
  background-color: #4ade80;
  outline: 1px solid white;
}

.utr_upload_error {
  background-color: #f87171;
  outline: 1px solid white;
  color: white;
}

/* UTR end */
/* nach pull download start */
.npfu_list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.npfu_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.npfu_navbar h3 {
  margin: 0px;
}

.npfu_navbar .button {
  all: unset;
  transition: all 0.3s ease-out;
  height: 52px;
  width: 52px;
  background-color: #eff5f6;
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.npfu_navbar .button:hover {
  background-color: #ddebee;
  transition: all 0.3s ease-out;
}

.npfu_navbar .button:active {
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.npfu_navbar .button img {
  width: 50%;
}

.npfu_action_block {
  padding-left: 10px;
  padding-right: 10px;
}

.npfu_action_button_block {
  display: flex;
  justify-content: space-between;
}

.npfu_action_block_header {
  display: flex;
  justify-content: space-between;
}

.npfu_file_preview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding: 15px 19px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}

.npfu_file_summary {
  font-size: 15px;
  width: 90%;
  text-align: justify;
  font-weight: 500;
}

.npfu_file_created_at {
  color: #a19f9f;
  font-size: 14px;
}

.npfu_file_status {
  width: 100px;
  height: 28px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: #8f9397;
  border-radius: 5px;
  padding: 0px 5px;
}

.npfu_rows_count {
  font-weight: bold;
  display: flex;
  flex-direction: row-reverse;
}

/* nach pull download end */
/* Responsive */
@media (min-width: 991px) {
  .utr_data_table td {
    font-size: small;
  }

  .utr_data_table th {
    font-size: medium;
  }

  .utr_table_block {
    overflow-x: auto;
  }

  /* .utr_table_block {
    overflow-x: unset;
  } */
}

/* @media (min-width:770px){
  .utr_table_block{
    overflow-x: unset;
  }
} */
@media (max-width: 390px) {
  .col_block {
    width: 140px;
  }

  img.searchbtn {
    background: #0ca8a8;
    height: 40px;
    padding: 25%;
    border-radius: 5px;
  }

  img.searchbtn-nach {
    height: 40px;
    cursor: pointer;
  }
  
  img.clearcookiebtn {
    height: 40px;
    cursor: pointer;
  }
  
  .bal_block {
    width: 140px;
  }

  .card-header-container .date-time span {
    color: #818485;
    font-size: 14px;
  }

  .amt-container .bal-amount {
    width: 130px;
  }

  .amt-container .hnd-amount {
    width: 130px;
  }

  .dn-red-amt {
    width: 60px;
  }

  .dis_block {
    width: 130px;
  }
}

@media (max-width: 407px) {
  .cash-id {
    font-size: 16px;
  }

  img.searchbtn {
    background: #0ca8a8;
    height: 40px;
    padding: 25%;
    border-radius: 5px;
  }

  img.searchbtn-nach {
    height: 40px;
    cursor: pointer;
  }
  
  img.clearcookiebtn {
    height: 40px;
    cursor: pointer;
  }

  .csoname-holder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {

  /* .card-border-small {
    height: 160px;
  } */
  img.searchbtn {
    background: #0ca8a8;
    height: 40px;
    padding: 15%;
    border-radius: 5px;
  }
}

img.searchbtn-nach {
  height: 40px;
  cursor: pointer;
}

img.clearcookiebtn {
  height: 40px;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .collected-holder {
    width: 30%;
    position: relative;
    display: inline-block;
    margin-left: -1%;
  }

  .balance-holder {
    display: inline-block;
    position: relative;
    padding-left: 0%;
    width: 30%;
  }

  .name-holder h2 {
    width: 350px;
  }

  /* .card-border-small {
    height: 170px;
  } */
}

/* Responsive End */

.nach_inactive_ops {
  background: #1467c5;
  margin-top: 3%;
  border-radius: 0 0 20px 20px;
  /* bottom: -10px; */
  margin-left: 5px;
}

.to_filter_values {
  font-size: 11px;
}

.NACH-history {
  background: #e94e29;
}

.loan_data {
  width: 70px;
  margin: 4%;
  padding: 8px;
  border-radius: 50px;
  text-align: -webkit-center;
  justify-content: center;
  vertical-align: middle;
}

/* .sms-holder {
  padding-top: 8%;
} */

.nb-spinner {
  width: 75px;
  height: 75px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #009688;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;
}

.t10-r10-25 {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.disabletile {
  opacity: 0.6;
  pointer-events: none;
}

/* UPI Auto Pay Start */
.search-box {
  background: #ffffff;
  box-shadow: 0px 0px 24px -12px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.search-btn {
  background: #13697e;
  box-shadow: 0px 0px 6px #eeebeb;
  border-radius: 7px;
  color: #ffffff;
  font-weight: 500;
  font-size: 17px;
}

.upi-card-holder {
  margin: 1% 0;
}

.sub-card-container {
  /* border: 1px solid; */
  /* height: 26rem; */
  border-radius: 20px;
  background: white;
  box-shadow: 0px 0px 1px 0px;
  /* width: 65rem; */
  display: flex;
  flex-direction: column;
}

.head-card-container {
  background: #13697e;
  padding: 2%;
  display: flex;
  border-radius: 19px 19px 0px 0px;
  justify-content: space-between;
}

/* .name-cont {
  width: 29%;
  height: 47%;
} */

.br-name {
  /* width: 26%;
  height: 47%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: white;
  padding: 1% 5%;
}

.household-name>h1 {
  font-weight: 600;
  color: white;
}

.hh-no>p {
  color: white;
}

.br-name>span {
  font-weight: 600;
  color: #13697e;
}

.bottom-card-container {
  border-radius: 0% 0px 19px 19px;
  justify-content: center;
  align-content: center;
  margin: 0 2%;
}

.bot-hed {
  position: relative;
  top: 14px;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 0px;
}

.f-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
}

.f-img {
  /* border: 1px solid; */
  /* height: 50px;
  width: 50px; */
  padding: 0 3%;
  margin-right: 2%;
}

.f-img>img {
  height: 60px;
  width: 60px;
  border-radius: 10px;
}

.sub-hed {
  display: -webkit-box;
  align-items: center;
  justify-content: center;
}

.hname {
  position: relative;
  bottom: 7px;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.hname>span {
  font-weight: bold;
  margin-bottom: 1%;
}

.hname>p {
  border-radius: 20px;
  background: #eff5f6;
  font-weight: 600;
  color: #13697e;
  text-align: center;
  padding: 3%;
}

.f-head>button {
  background: #13697e;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  padding: 1% 5%;
  font-size: 17px;
}

.choosing-container {
  /* display: flex; */
  justify-content: center;
  padding: 10px;
}

.choosing-container>ul {
  border-radius: 23px;
  display: flex;
  align-items: center;
  background: #f8f9fa;
  padding: 1% 4%;
  justify-content: space-between;
}

.choosing-container>ul>li {
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 8%;
  column-gap: 5%;
}

.choosing-container>ul>.first {
  display: flex;
  align-items: center;
  grid-column-gap: 2%;
  justify-content: center;
}

li.first span {
  margin-left: 3%;
  font-size: 20px;
  color: #000000;
}

.choosing-container>ul>.verify-sec>.second {
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-right: 10%;
  background: #eff5f6;
  padding: 3%;
}

li.verify-sec2 li {
  margin-right: 10%;
}

.choosing-container>ul>.verify-sec {
  /* display: flex;
  justify-content: space-around; */
}

.choosing-container>ul>.verify-sec>.verify-sec2 {
  /* border: 1px solid; */
  /* width: 36%; */
  display: flex;
  justify-content: space-around;
}

.choosing-container>ul>.verify-sec>.second>span {
  color: green;
  font-weight: 500;
  font-size: 20px;
}

.choosing-container>ul>.verify-sec>.second>img {
  height: 2rem;
  width: 2rem;
}

.choosing-container>ul>.verify-sec>.verify-sec2>.third>img {
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10%;
  border-radius: 50px;
}

.choosing-container>ul>.verify-sec>.verify-sec2>.forth>img {
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10%;
  border-radius: 50px;
}

.choosing-container>ul>.verify-sec>.verify-sec2>.fifth>img {
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10%;
  border-radius: 50px;
}

.choosing-container>ul>.verify-sec>.verify-sec2>.sixth>img {
  height: 3rem;
  width: 3rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20%;
  border-radius: 50px;
}

.choosing-container>ul>.verify-sec>.no {
  display: flex;
  justify-content: center;
}

.choosing-container>ul>.verify-sec>.no>img {
  display: none;
}

.choosing-container>ul>.verify-sec>.no>span {
  color: #13697e;
}

/* Responsive */
@media (min-width: 991px) {
  .utr_data_table td {
    font-size: small;
  }

  .utr_data_table th {
    font-size: medium;
  }

  .utr_table_block {
    overflow-x: auto;
  }

  /* .utr_table_block {
    overflow-x: unset;
  } */
}

/* @media (min-width:770px){
  .utr_table_block{
    overflow-x: unset;
  }
} */
@media (max-width: 390px) {
  /* .sub-card-container {
    width: 352px;
    height: 38rem;
  } */

  .head-card-container {
    /* height: 9rem; */
  }

  .br-name {
    /* width: 25%;
    height: 25%; */
    padding: 1% 5%;
  }

  .choosing-container>ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12%;
  }

  .f-head {
    /* height: 28%;
    column-gap: 22%; */
  }

  /* .sub-hed {
    width: 54%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 1%;
    column-gap: 9%;
  } */

  .choosing-container>ul>.verify-sec {
    width: 117%;
  }

  /* .sub-hed {
  } */
  img.searchbtn {
    background: #0ca8a8;
    height: 40px;
    padding: 25%;
    border-radius: 5px;
  }
  
  img.searchbtn-nach {
    height: 40px;
    cursor: pointer;
  }
  
  img.clearcookiebtn {
    height: 40px;
    cursor: pointer;
  }

  .bal_block {
    width: 140px;
  }

  .card-header-container .date-time span {
    color: #818485;
    font-size: 14px;
  }

  .amt-container .bal-amount {
    width: 130px;
  }

  .amt-container .hnd-amount {
    width: 130px;
  }

  .dn-red-amt {
    width: 60px;
  }

  .dis_block {
    width: 130px;
  }
}

@media (max-width: 430px) {
  /* .sub-card-container {
    width: 373px;
    height: 38rem;
  } */

  .head-card-container {
    /* height: 9rem; */
  }

  .br-name {
    /* width: 25%;
    height: 25%; */
    padding: 1% 5%;
  }

  .choosing-container>ul {
    height: 100%;
    width: 100%;
    column-gap: 17%;
    /* display: flex;
    flex-direction: column;
    row-gap: 12%; */
  }

  .choosing-container>ul>.verify-sec>.verify-sec2 {
    width: 8rem;
    height: 2rem;
  }

  .choosing-container>ul>.first>span {
    font-size: 9px;
  }

  .choosing-container>ul>.verify-sec>.second>span {
    /* color: green;
    font-weight: 500; */
    font-size: 9px;
  }

  .choosing-container>ul>.verify-sec>.second>img {
    height: 1rem;
    width: 1rem;
  }

  /* .choosing-container>ul>.verify-sec>.second {
    width: 100%;
  } */

  .choosing-container {
    height: 12%;
  }

  .choosing-container>ul>.verify-sec {
    /* display: flex; */
    width: 100%;
    /* justify-content: space-between; */
    /* column-gap: 17px; */
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.third {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.third>img {
    height: 1rem;
    width: 1rem;
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.forth {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.forth>img {
    height: 1rem;
    width: 1rem;
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.fifth {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
  }

  .choosing-container>ul>.verify-sec>.verify-sec2>.fifth>img {
    height: 1rem;
    width: 1rem;
  }

  .f-head {
    /* height: 28%;
    column-gap: 16%; */
  }

  .f-head>button {
    height: 19%;
    width: 22%;
    font-size: 9px;
  }

  /* .sub-hed {
    width: 54%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: 1%;
    column-gap: 9%;
  } */
}

@media only screen and (max-width: 992px) {

  /* .card-border-small {
    height: 160px;
  } */
  img.searchbtn {
    background: #0ca8a8;
    height: 40px;
    padding: 15%;
    border-radius: 5px;
  }
}

img.searchbtn-nach {
  height: 40px;
  cursor: pointer;
}

img.clearcookiebtn {
  height: 40px;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .collected-holder {
    width: 30%;
    position: relative;
    display: inline-block;
    margin-left: -1%;
  }

  .balance-holder {
    display: inline-block;
    position: relative;
    padding-left: 0%;
    width: 30%;
  }

  .name-holder h2 {
    width: 350px;
  }

  /* .card-border-small {
    height: 170px;
  } */
}

.addvpaButton {
  background: #13697E;
  border: 1px solid rgba(11, 115, 140, 0.66);
  border-radius: 7px;
  padding: 3%;
  width: 80%;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.vpaAddress {
  background: #FFFFFF;
  border: 1px solid rgba(11, 115, 140, 0.66);
  border-radius: 7px;
  margin-bottom: 1%;
}

.disable {
  background: grey;
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}

.default_vpa {
  height: 35px;
  width: 35px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}

.user_icon img {
  margin-top: 50%;
  width: 80%;
  height: 80%;
}

.logo-area {
  display: flex
;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
/* Responsive End */
/* .choosing-container > ul > .n > img {
  color: #a19f9f;
} */
/* .upi-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 6px #EEEBEB;
  border-radius: 7px;
  padding-bottom: 1%;
  margin-top: 3%;
}

.upi-hhdata {
  background: #13697E;
  box-shadow: 0px 0px 6px #EEEBEB;
  border-radius: 7px 7px 0px 0px;
  padding: 1% 2%;
  color: #ffffff;
  display: flex;
  display: -webkit-box;
  flex-wrap: wrap;
}

.upi-hhname {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.upi-hhno {
  font-size: 16px;
}

.upi-branch {
  border: 1px solid;
  padding: 4% 6%;
  text-align: center;
  font-size: 16px;
  background: #FFFFFF;
  border-radius: 21px;
  color: #13697E;
  font-weight: 600;
  justify-content: center;
}

.vpa-card {
  margin: 1% 1%;
  border: 1px solid;
  background: #FFFFFF;
  box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  color: #000000;
  font-size: 17px;
  font-weight: 600;
}

.primary-vpa {
  padding: 0 2%;
}

.vpa-data-holder {
  padding: 1% 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.verify-status img {
  height: 35px;
  width: 35px;
  margin-left: 5px;
  cursor: pointer;
}

.vpadata-left {
  display: flex;
  padding-top: 1%;
}

.vpa-id {
  padding: 3%;
  font-size: 22px;
}

.register-holder img {
  width: 22px;
  height: 22px;
}

.register-holder span {
  font-size: 17px;
  padding: 2px 5px;
}

.register-holder {
  padding: 5% 10%;
  display: flex;
  border-radius: 100px;
  background: rgba(0, 213, 102, 0.11);
  width: 160px;
  color: #0CA304;
}

.right-arrow img {
  height: 35px;
  width: 35px;
}

.right-arrow {
  padding: 5px 6px 0px;
  cursor: pointer;
}

.vpadata-right {
  display: flex;
}

.add-vpabtn {
  text-align: right;
  padding: 1%;
}

.add-vpabtn button {
  background: #FFFFFF;
  border: 1px solid rgba(19, 105, 126, 0.6);
  border-radius: 100px;
  padding: 1% 3%;
  color: #13697E;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.border11 {
  border-radius: 11px;
}

.modalhead {
  background: #13697E;
  border-radius: 7px 7px 0px 0px;
}

.modalhead h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
}

.modalhead span {
  color: #ffffff;
}
 */

/* UPI Auto Pay End */

.tot_amt_block {
  margin: auto;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.amt_b {
  width: 32%;
  max-width: 130px;
  margin-bottom: 1%;
}

.blue_block_sh {
  background-color: rgba(31, 161, 193, .16);
  color: #1fa1c1;
}

.red_block_sh {
  background-color: #ffbea5;
  color: #8a472d;
}

.green_block_sh {
  background-color: #9bff95;
  color: #2f5d2c;
}

.block_sh {
  width: 100%;
  border-radius: 15px;
  margin-top: 10%;
  padding-top: 10%;
  padding-bottom: 10%;
  margin-bottom: 5%;
}

.countLabel {
  font-size: 20px;
}

.bluetext {
  color: #20a1c1;
  font-size: 16px;
}

.redtext {
  color: #8a472d;
  font-size: 16px;
}

.greentext {
  color: #2f5d2c;
  font-size: 16px;
}
.small-icon-sms {
  width: 70%;  /* Adjust the width as needed */
}

.small-icon-head {
  height: 0%;
  width: 27%;
}

.sms-email{
  padding: 0px !important;
  border-radius: 50%;
}

@media (min-width: 481px) and (max-width: 767px) {  /*  Media for Mobile responsive*/

.small-icon-head {
  height: 0%;
  width: 41%;
}
.sms-email-holder-align {
  padding-top: 5px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .small-icon-head {
    width: 41%;
  }
  .sms-email-holder-align {
    padding-top: 5px;
  }
  .small-icon-sms {
    margin: 4px;
}
}


